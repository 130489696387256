import { defineStore } from 'pinia';
import { useStore } from 'vuex';
import { router } from '@inertiajs/vue3';
import { ref, computed } from 'vue';
import { flashMessage } from '@/composeables/helpers';
import { useWatchlistsBuilderApi } from '@/components/watchlists/WatchlistBuilderApi';
const watchlistApi = useWatchlistsBuilderApi();

export const useWatchlistStore = defineStore('watchlists', () => {
  const $store = useStore();

  /*************************************************STATE VARIABLES**************************************************/
  const entities = ['coins', 'companies', 'apefluencer_accounts', 'news_items', 'onchain_addresses'];
  const entitiesNames = {
    coins: 'coins',
    companies: 'companies',
    apefluencer_accounts: 'apefluencers',
    news_items: 'newsFolders',
    onchain_addresses: 'onchainFolders'
  };
  const userWatchlists = ref({});
  const teamWatchlists = ref({});
  const loaded = ref({
    teams: false
  });

  /*****************************************************GETTERS********************************************************/
  const coins = computed(() => userWatchlists.value.coins || []);
  const companies = computed(() => userWatchlists.value.companies || []);
  const apefluencers = computed(() => userWatchlists.value.apefluencer_accounts || []);
  const newsFolders = computed(() => userWatchlists.value.news_items || []);
  const onchainFolders = computed(() => userWatchlists.value.onchain_addresses || []);
  const teams = computed(() => {
    const watchlists = {};
    entities.forEach(entity => {
      watchlists[entitiesNames[entity]] = teamWatchlists.value[entity] || [];
    });
    return watchlists;
  });
  const watchlistLoader = computed(() => {
    const loaders = { teams: loaded.value.teams };
    entities.forEach(entity => {
      loaders[entitiesNames[entity]] = loaded.value[entity] || false;
    });
    return loaders;
  });

  /*************************************************ACTION FUNCTIONS**************************************************/
  function initialize() {
    // LOOP OVER ENTITIES TO FILL STATE
    entities.forEach(entity => {
      userWatchlists.value[entity] = [];
      teamWatchlists.value[entity] = [];
      loaded.value[entity] = false;
    });
    setAllWatchlists(true);
  }

  function getEntityName(entity) {
    return entitiesNames[entity] || '';
  }

  async function setAllWatchlists(teams = false) {
    let response = await watchlistApi.getAllWatchlists('all', teams);
    let userWatchlists = dataPreProcessing(response.user_watchlists);
    entities.forEach(entity => {
      setEntityWatchlists(
        entity,
        userWatchlists.filter(x => x.watchlist_type == entity)
      );
      setEntityWatchlists(
        entity,
        response.team_watchlists.filter(x => x.watchlist_type == entity),
        true
      );
    });
    setLoaded('all', true);
    setLoaded('all', true, true);
  }

  async function setWatchlists(entity) {
    let response = await watchlistApi.getAllWatchlists(entity);
    setEntityWatchlists(entity, dataPreProcessing(response.user_watchlists));
    setLoaded(entity, true);
  }

  function dataPreProcessing(watchlists) {
    watchlists.forEach(watchlist => {
      watchlist.team_entity_permissions = watchlist.team_entity_permissions?.find(
        x => x.team_id == $store.state.session.user.team_id
      )
        ? true
        : false;
      watchlist.can_read = watchlist.user_entity_permissions[0].can_read;
      watchlist.can_write = watchlist.user_entity_permissions[0].can_write;
      watchlist.is_subscribed =
        watchlist.user_entity_permissions[0].can_read && !watchlist.user_entity_permissions[0].can_write;
    });
    return watchlists;
  }

  async function subscribeToWatchlists(ids) {
    const promises = [];
    ids.forEach(id => {
      promises.push(watchlistApi.subscribeToWatchlist(id));
    });
    Promise.all(promises).then(async () => {
      setLoaded('all', false);
      setLoaded('all', false, true);
      flashMessage({
        type: 'success',
        message: `Watchlist${ids.length == 1 ? '' : 's'} subscribed successfully`
      });
      await setAllWatchlists(true);
    });
  }

  async function shareWatchlistsToTeam(ids) {
    const promises = [];
    ids.forEach(id => {
      promises.push(watchlistApi.shareWatchlistToTeam(id));
    });
    Promise.all(promises).then(async () => {
      setLoaded('all', false);
      setLoaded('all', false, true);
      flashMessage({
        type: 'success',
        message: `Watchlist${ids.length == 1 ? '' : 's'} shared successfully`
      });
      await setAllWatchlists(true);
    });
  }

  function getWatchlistFromStore(id, teams = false) {
    let watchlists = [];
    entities.forEach(entity => {
      watchlists = [...watchlists, ...(teams ? teamWatchlists.value[entity] : userWatchlists.value[entity])];
    });
    return watchlists.find(w => w.id == id);
  }

  async function getWatchlist(id, teams = false) {
    let watchlist = getWatchlistFromStore(id, teams);
    if (watchlist && teams) {
      watchlist.entities_uids =
        watchlist.watchlist_type == 'coins'
          ? watchlist.coins.map(x => x.uid)
          : watchlist[watchlist.watchlist_type].map(x => x.id);
    }

    if (watchlist) {
      return {
        ...watchlist,
        entities:
          watchlist.watchlist_type == 'coins'
            ? [...new Set($store.getters.coins.filter(x => watchlist.entities_uids.includes(x.uid)).map(x => x.id))]
            : watchlist.entities_uids
      };
    } else {
      let response = await watchlistApi.getWatchlist(id);
      return {
        ...response,
        entities: response.entities.map(i => i.id)
      };
    }
  }

  async function getCompanies() {
    let response = await watchlistApi.getCompanies();
    return response;
  }

  async function getTeammates() {
    let response = await watchlistApi.getTeammates();
    return response;
  }

  async function createWatchlist(payload) {
    let response = await watchlistApi.createWatchlist(payload);
    setLoaded(payload.watchlist_type, false);
    setLoaded(payload.watchlist_type, false, true);
    await setAllWatchlists(true);
    return response;
  }

  async function updateWatchlist(watchlistPayload, defaultPayload = {}) {
    let response = await watchlistApi.updateWatchlist(watchlistPayload, defaultPayload, $store);
    setLoaded(watchlistPayload.watchlist_type, false);
    setLoaded(watchlistPayload.watchlist_type, false, true);
    await setAllWatchlists(true);
    return response;
  }

  async function updateWatchlistEntity(payload) {
    await watchlistApi.updateWatchlistEntity(payload);
    setLoaded(payload.entity, false);
    await setWatchlists(payload.entity);
  }

  async function deleteWatchlist(watchlistId, action = null) {
    await watchlistApi.deleteWatchlist(watchlistId, action);
    deleteWatchlistFromStore(watchlistId);
  }

  async function deleteWatchlistEntity(payload) {
    await watchlistApi.deleteWatchlistEntity(payload);
    setLoaded(payload.entity, false);
    await setWatchlists(payload.entity);
  }

  async function deleteUserEntityPermission(id) {
    await watchlistApi.deleteUserEntityPermission(id);
    deleteWatchlistFromStore(id);
  }

  async function includedInWatchlist(entityObj, entity = 'coins') {
    const watchlists = userWatchlists.value[entity];
    const id = entity == 'coins' ? entityObj.uid : entityObj.id;
    return watchlists.filter(x => x.entities_uids.includes(id)).map(i => i.id);
  }

  async function openAddToWatchlistModal(entityObj, mode, entity) {
    const w_ids = await includedInWatchlist(entityObj, entity);
    $store.commit(
      'openModal',
      {
        name: 'addToWatchlist',
        status: true,
        payload: {
          payload: { ...entityObj, watchlist_ids: w_ids },
          mode: mode,
          entity: entity
        }
      },
      { root: true }
    );
  }

  async function openWatchlistDeleteModal(watchlistId) {
    $store.dispatch('setWatchlistAlertsModal', {
      status: true,
      payload: { watchlistId: watchlistId }
    });
  }

  async function unsubscribeWatchlist(id) {
    await $store.dispatch('confirm', 'Please confirm you want to unsubscribe this watchlist?');
    await deleteUserEntityPermission(id);
  }

  function visitCreatePage(entity, entityIds = null) {
    window.localStorage.removeItem('prefillWatchlistId');
    window.localStorage.removeItem('prefillWatchlistEntityIds');
    if (entityIds) window.localStorage.setItem('prefillWatchlistEntityIds', entityIds);
    window.localStorage.setItem('prefillWatchlistType', entity);
    router.visit('/watchlists/create');
  }

  function visitEditPage(entity, watchlistId) {
    window.localStorage.removeItem('prefillWatchlistId');
    window.localStorage.removeItem('prefillWatchlistEntityIds');
    window.localStorage.setItem('prefillWatchlistId', watchlistId);
    window.localStorage.setItem('prefillWatchlistType', entity);
    router.visit('/watchlists/create');
  }

  function clearWatchlistStorage() {
    window.localStorage.removeItem('prefillWatchlistType');
    window.localStorage.removeItem('prefillWatchlistEntityIds');
    window.localStorage.removeItem('prefillWatchlistId');
    window.localStorage.removeItem('prefillWatchlistName');
  }

  /*************************************************MUTATION FUNCTIONS**************************************************/
  function setLoaded(type, value, teams = false) {
    if (teams) {
      loaded.value.teams = value;
    } else {
      if (type == 'all') {
        entities.forEach(entity => {
          loaded.value[entity] = value;
        });
      } else loaded.value[type] = value;
    }
  }

  function setEntityWatchlists(entity, data, teams = false) {
    if (teams) {
      teamWatchlists.value[entity] = data;
    } else {
      userWatchlists.value[entity] = data;
    }
  }

  function deleteWatchlistFromStore(id) {
    const watchlist = getWatchlistFromStore(id);
    if (watchlist) {
      const type = watchlist.watchlist_type;
      const index = userWatchlists.value[type].findIndex(x => x.id == watchlist.id);
      if (index != -1) userWatchlists.value[type].splice(index, 1);
    }
  }

  return {
    userWatchlists,
    teamWatchlists,
    loaded,
    coins,
    companies,
    apefluencers,
    newsFolders,
    onchainFolders,
    teams,
    watchlistLoader,
    initialize,
    subscribeToWatchlists,
    getEntityName,
    getWatchlist,
    getWatchlistFromStore,
    getCompanies,
    getTeammates,
    createWatchlist,
    updateWatchlist,
    deleteWatchlist,
    shareWatchlistsToTeam,
    updateWatchlistEntity,
    deleteWatchlistEntity,
    deleteUserEntityPermission,
    openAddToWatchlistModal,
    openWatchlistDeleteModal,
    unsubscribeWatchlist,
    visitCreatePage,
    visitEditPage,
    clearWatchlistStorage
  };
});
