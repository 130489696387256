import axios from 'axios';
import qs from 'qs';

export async function fetchCoins(items, filters) {
  const response = await axios.get('/data/coins', {
    params: {
      items: items,
      filters: filters
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    }
  });
  let coin_data = response.data.coin_data.map(coin => {
    return { ...coin, coin: { name: coin.name, ticker: coin.ticker } };
  });
  coin_data = coin_data.map(data =>
    Object.keys(data).reduce((acc, key) => {
      const updatedKey = response.data.coin_datapoints_keys?.[key] || key;
      acc[updatedKey] = data[key];

      return acc;
    }, {})
  );
  return coin_data;
}

export async function fetchFilteredCoins(params) {
  const response = await axios.post('/data/coins/coin_data', {
    ...params
  });
  let coin_data = response.data.coin_data.map(coin => {
    return { ...coin, coin: { name: coin.name, ticker: coin.ticker } };
  });
  return coin_data;
}

export async function fetchCoinMetadata() {
  const response = await axios.get('/data/coin_metadata', {});
  return response.data;
}

export async function fetchSentiment(categories) {
  return axios.post('/data/collective_coins/sentiment', {
    categories: categories
  });
}

export async function fetchTwitterData(categories) {
  return axios.post('/data/collective_coins/twitter', {
    categories: categories
  });
}

export async function fetchUniversalData() {
  return (await axios.get('/data/coin_universe')).data;
}

export async function fetchCategories() {
  return (await axios.get('/data/categories')).data;
}

export async function loadStaticData() {
  return (await axios.get('/data/static_data')).data;
}
export async function loadUserNotifications() {
  return (await axios.get('/notifications', { params: { offset: 0, limit: 100 } })).data;
}

export async function fetchIntegrationTeamCoins() {
  const response = await axios.get('/integrations/coins');
  return response.data.data.map(coin => {
    return { ...coin, coin: { name: coin.name, ticker: coin.ticker } };
  });
}

export async function fetchIntegrationCategories() {
  return (await axios.get('/integrations/categories')).data;
}
