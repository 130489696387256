import axios from 'axios';

export function useWatchlistsBuilderApi() {
  const subscribeToWatchlist = async teamWatchlistId => {
    await axios.patch('/user_entity_permissions/0', {
      entity_permissionable_id: teamWatchlistId,
      entity_permissionable_type: 'Watchlist',
      can_read: true,
      can_write: false
    });
  };

  const shareWatchlistToTeam = async watchlistId => {
    await axios.post('/team_entity_permissions', {
      entity_permissionable_type: 'Watchlist',
      entity_permissionable_id: watchlistId
    });
  };

  const getWatchlist = async watchlistId => {
    const watchlistsResponse = await axios.get(`/data/watchlists/${watchlistId}`);

    return watchlistsResponse.data;
  };

  const updateMaintainers = async (currentMaintainers, newMaintainers, watchlistId) => {
    const maintainersToRemove = currentMaintainers.filter(m => !newMaintainers.includes(m));
    const maintainersToAdd = newMaintainers.filter(m => !currentMaintainers.includes(m));

    maintainersToRemove.forEach(async maintainer => {
      await axios.patch('/user_entity_permissions/0', {
        user_id: maintainer,
        entity_permissionable_id: watchlistId,
        entity_permissionable_type: 'Watchlist',
        can_read: true,
        can_write: false
      });
    });

    maintainersToAdd.forEach(async maintainer => {
      await axios.patch('/user_entity_permissions/0', {
        user_id: maintainer,
        entity_permissionable_id: watchlistId,
        entity_permissionable_type: 'Watchlist',
        can_read: true,
        can_write: true
      });
    });
  };

  const getAllWatchlists = async (entity, teams = false) => {
    const watchlistsResponse = await axios.get('/data/watchlists', {
      params: {
        ...(entity != 'all' ? { type: entity } : {}),
        ...(teams ? { teams: true } : {})
      }
    });
    return watchlistsResponse.data;
  };

  const getCompanies = async () => {
    const companiesResponse = await axios.get('/companies');

    return companiesResponse.data;
  };

  const getTeammates = async () => {
    const teammatesResponse = await axios.get('/teammates');
    return teammatesResponse.data.map(u => {
      return {
        ...u,
        label: u.first_name || u.last_name || u.email
      };
    });
  };

  const createWatchlist = async watchlistPayload => {
    const response = await axios.post('/watchlists', {
      name: watchlistPayload.name,
      watchlist_type: watchlistPayload.watchlist_type,
      entities: watchlistPayload.selectedEntities || watchlistPayload.entities
    });

    const watchlistId = response.data.watchlist?.id;

    if (watchlistPayload.shareWatchlist) {
      await axios.post('/team_entity_permissions', {
        entity_permissionable_type: 'Watchlist',
        entity_permissionable_id: watchlistId
      });

      if (watchlistPayload.maintainers.length > 0) {
        watchlistPayload.maintainers.forEach(async maintainer => {
          await axios.patch('/user_entity_permissions/0', {
            user_id: maintainer,
            entity_permissionable_id: watchlistId,
            entity_permissionable_type: 'Watchlist',
            can_read: true,
            can_write: true
          });
        });
      }
    }

    if (watchlistPayload.createNewsFeed) {
      await axios.post('/news_feeds', {
        name: watchlistPayload.name,
        watchlists: [watchlistId],
        hide_tweets: true,
        hide_low_quality: true,
        hide_translated_news: true
      });
    }

    return response.data.watchlist || response.data;
  };

  const updateWatchlist = async (watchlistPayload, defaultPayload, store) => {
    const watchlistId = watchlistPayload.selectedWatchlist.id;

    const response = await axios.patch(`/watchlists/${watchlistId}`, {
      name: watchlistPayload.name,
      watchlist_type: watchlistPayload.watchlist_type,
      entities: watchlistPayload.selectedEntities,
      empty: watchlistPayload.empty
    });

    if (watchlistPayload.shareWatchlist != defaultPayload.shareWatchlist) {
      if (watchlistPayload.shareWatchlist) {
        await axios.post('/team_entity_permissions', {
          entity_permissionable_type: 'Watchlist',
          entity_permissionable_id: watchlistId
        });

        await updateMaintainers(defaultPayload.maintainers, watchlistPayload.maintainers, watchlistId);
      } else {
        await store.dispatch(
          'confirm',
          `Disabling share will remove team's access to this ${
            watchlistPayload.watchlist_type == 'onchain_addresses' ? 'folder' : 'watchlist'
          }. Please confirm if you want to proceed.`
        );
        await axios.delete('/team_entity_permissions/0', {
          params: {
            entity_permissionable_type: 'Watchlist',
            entity_permissionable_id: watchlistId
          }
        });
      }
    } else {
      if (watchlistPayload.maintainers)
        await updateMaintainers(defaultPayload.maintainers, watchlistPayload.maintainers, watchlistId);
    }
    return response.data.watchlist;
  };

  const deleteWatchlist = async (id, action) => {
    await axios.delete(`/watchlists/${id}`, { params: { alert_action: action } });
  };

  const updateWatchlistEntity = async payload => {
    await axios.post('/watchlist_entity', payload);
  };

  const deleteWatchlistEntity = async payload => {
    await axios.delete(`/watchlist_entity/${payload.entity_id}`, { params: payload });
  };

  const deleteUserEntityPermission = async id => {
    await axios.delete('/user_entity_permissions/0', {
      params: {
        entity_permissionable_id: id,
        entity_permissionable_type: 'Watchlist'
      }
    });
  };

  return {
    subscribeToWatchlist,
    getWatchlist,
    getCompanies,
    getTeammates,
    getAllWatchlists,
    createWatchlist,
    updateWatchlist,
    deleteWatchlist,
    shareWatchlistToTeam,
    updateWatchlistEntity,
    deleteWatchlistEntity,
    deleteUserEntityPermission
  };
}
